// src/app/services/auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  getAuthenticated(): Observable<any> {
    return this.http.get<{ token: string }>(`${environment.apiUrl}/user/getAuthenticated`);
  }
}
